import { DateTime } from "luxon";

export const ffttApiConfig = {
  equipe: {
    configParams: { numclu: "11310047", type: "A" },
    url: "http://www.fftt.com/mobile/pxml/xml_equipe.php",
    parser: (result) => {
      const date = DateTime.now();
      const beginSecondPhase = DateTime.fromObject({
        year: date.toFormat("yyyy"),
        month: 1,
        day: 7,
        hour: 0,
        minute: 0,
      });
      const endSecondPhase = DateTime.fromObject({
        year: date.toFormat("yyyy"),
        month: 8,
        day: 1,
        hour: 0,
        minute: 0,
      });
      const phase = date > beginSecondPhase && date < endSecondPhase ? 2 : 1;
      if (result.liste === "") {
        return [];
      }
      return result.liste.equipe
        .map((item) => {
          item.phase = parseInt(item.libequipe.slice(-1), 10);
          item.libequipe = item.libequipe.substr(0, item.libequipe.length - 10);
          const urlParams = new URLSearchParams(item.liendivision);
          item.poule = urlParams.get("cx_poule");
          item.division = urlParams.get("D1");
          return item;
        })
        .filter((item) => item.phase === phase)
        .sort(
          (a, b) =>
            a.libequipe.localeCompare(b.libequipe) ||
            a.libepr.localeCompare(b.libepr)
        );
    },
  },
  divisionResults: {
    configParams: { action: "", auto: 1 },
    url: "http://www.fftt.com/mobile/pxml/xml_result_equ.php",
    parser: (result) => {
      return result;
    },
  },
  divisionRanking: {
    configParams: { action: "classement", auto: 1 },
    url: "http://www.fftt.com/mobile/pxml/xml_result_equ.php",
    parser: (result) => {
      return result;
    },
  },
  resultDetails: {
    configParams: {},
    url: "http://www.fftt.com/mobile/pxml/xml_chp_renc.php",
    parser: (result) => {
      return result;
    },
  },
  history: {
    configParams: {},
    url: "http://www.fftt.com/mobile/pxml/xml_histo_classement.php",
    parser: (result) => {
      return result;
    },
  },
  player: {
    configParams: {},
    url: "http://www.fftt.com/mobile/pxml/xml_licence_o.php",
    parser: (result) => {
      return result;
    },
  },
  indivRanking: {
    configParams: { action: "classement" },
    url: "http://www.fftt.com/mobile/pxml/xml_result_indiv.php",
    parser: (result) => {
      return result.liste.classement;
    },
  },
  matchsSpid: {
    configParams: {},
    url: "http://www.fftt.com/mobile/pxml/xml_partie.php",
    parser: (result) => {
      return result.liste !== "" ? result.liste.partie : [];
    },
  },
  matchsOff: {
    configParams: {},
    url: "http://www.fftt.com/mobile/pxml/xml_partie_mysql.php",
    parser: (result) => {
      return result.liste !== "" ? result.liste.partie : [];
    },
  },
};
